[data-color="color_1"]{
	
	/* Primary */
	--primary: #a6a6a6;
	--primary-hover: #919191;
	--primary-dark: #737373;
	
	/* Primary RGBA */
	--rgba-primary-1: rgba(166, 166, 166, 0.1);
	--rgba-primary-2: rgba(166, 166, 166, 0.2);
	--rgba-primary-3: rgba(166, 166, 166, 0.3);
	--rgba-primary-4: rgba(166, 166, 166, 0.4);
	--rgba-primary-5: rgba(166, 166, 166, 0.5);
	--rgba-primary-05: rgba(166, 166, 166, 0.05);
	--rgba-primary-6: rgba(166, 166, 166, 0.6);
	--rgba-primary-7: rgba(166, 166, 166, 0.7);
	--rgba-primary-8: rgba(166, 166, 166, 0.8);
	--rgba-primary-9: rgba(166, 166, 166, 0.9);
	
	/* Other variables */
	--theme-text-color: #ffffff;
	--secondary: #888888;
	--title: #222222;
	--bg-light: #f4f4f4;
}


[data-color="color_2"] {
	/* Primary */
	--primary: #FF0000; /* red */
	--primary-hover: #CC0000; /* slightly lighter red */
	--primary-dark: #990000; /* darker red */
  
	/* Primary RGBA */
	--rgba-primary-1: rgba(255, 0, 0, 0.1);
	--rgba-primary-2: rgba(255, 0, 0, 0.2);
	--rgba-primary-3: rgba(255, 0, 0, 0.3);
	--rgba-primary-4: rgba(255, 0, 0, 0.4);
	--rgba-primary-5: rgba(255, 0, 0, 0.5);
	--rgba-primary-05: rgba(255, 0, 0, 0.05);
	--rgba-primary-6: rgba(255, 0, 0, 0.6);
	--rgba-primary-7: rgba(255, 0, 0, 0.7);
	--rgba-primary-8: rgba(255, 0, 0, 0.8);
	--rgba-primary-9: rgba(255, 0, 0, 0.9);
  
	/* Other variables */
	--theme-text-color: #ffffff; /* white */
	--secondary: #ffffff; /* white */
	--title: #FF0000; /* red */
	--bg-light: #FF0000; /* red */
  }
  

  [data-color="color_3"] {
	/* Lemonish primary color */
	--primary: #f4da64;
	--primary-hover: #e3c335; /* Slightly darker shade for hover effect */
	--primary-dark: #b5a052; /* Darker shade for contrast */
  
	/* Primary RGBA */
	--rgba-primary-1: rgba(244, 218, 100, 0.1); /* Lighter shade for opacity */
	--rgba-primary-2: rgba(244, 218, 100, 0.2);
	--rgba-primary-3: rgba(244, 218, 100, 0.3);
	--rgba-primary-4: rgba(244, 218, 100, 0.4);
	--rgba-primary-5: rgba(244, 218, 100, 0.5); /* Medium shade for opacity */
	--rgba-primary-05: rgba(244, 218, 100, 0.05); /* Very light shade for opacity */
	--rgba-primary-6: rgba(244, 218, 100, 0.6);
	--rgba-primary-7: rgba(244, 218, 100, 0.7);
	--rgba-primary-8: rgba(244, 218, 100, 0.8);
	--rgba-primary-9: rgba(244, 218, 100, 0.9); /* Darker shade for opacity */
  
	/* Other variables */
	--theme-text-color: #fff; /* Text color against lemonish background */
	--secondary: #cacf7a; /* Secondary color */
	--title: #222222; /* Title color */
	--bg-light: #ffeff0; /* Light background color */
  }
  
[data-color="color_4"]{
	
	// Primary
	--primary: #A75D5D;
	--primary-hover: #954c4c;
	--primary-dark: #5e2c2c;
	
	// Primary RGBA
	--rgba-primary-1: rgba(167,93,93,0.1);
	--rgba-primary-2: rgba(167,93,93,0.2);
	--rgba-primary-3: rgba(167,93,93,0.3);
	--rgba-primary-4: rgba(167,93,93,0.4);
	--rgba-primary-5: rgba(167,93,93,0.5);
	--rgba-primary-05: rgba(167,93,93,0.05);
	--rgba-primary-6: rgba(167,93,93,0.6);
	--rgba-primary-7: rgba(167,93,93,0.7);
	--rgba-primary-8: rgba(167,93,93,0.8);
	--rgba-primary-9: rgba(167,93,93,0.9);
	
	// Other
	--theme-text-color: #fff;
	--secondary: #FFC3A1;
	--title: #222222;
	--bg-light: #fff2f2;
}

[data-color="color_5"]{
	
	// Primary
	--primary: #EA5C2B;
	--primary-hover: #bf4115;
	--primary-dark: #ad2c00;
	
	// Primary RGBA
	--rgba-primary-1: rgba(234,92,43,0.1);
	--rgba-primary-2: rgba(234,92,43,0.2);
	--rgba-primary-3: rgba(234,92,43,0.3);
	--rgba-primary-4: rgba(234,92,43,0.4);
	--rgba-primary-5: rgba(234,92,43,0.5);
	--rgba-primary-05: rgba(234,92,43,0.05);
	--rgba-primary-6: rgba(234,92,43,0.6);
	--rgba-primary-7: rgba(234,92,43,0.7);
	--rgba-primary-8: rgba(234,92,43,0.8);
	--rgba-primary-9: rgba(234,92,43,0.9);
	
	// Other
	--theme-text-color: #fff;
	--secondary: #ebce5a;
	--title: #222222;
	--bg-light: #fff6f3;
	
}

[data-color="color_6"]{
	
	// Primary
	--primary: #A9907E;
	--primary-hover: #91735e;
	--primary-dark: #6a4a33;
	
	// Primary RGBA
	--rgba-primary-1: rgba(169,144,126,0.1);
	--rgba-primary-2: rgba(169,144,126,0.2);
	--rgba-primary-3: rgba(169,144,126,0.3);
	--rgba-primary-4: rgba(169,144,126,0.4);
	--rgba-primary-5: rgba(169,144,126,0.5);
	--rgba-primary-05: rgba(169,144,126,0.05);
	--rgba-primary-6: rgba(169,144,126,0.6);
	--rgba-primary-7: rgba(169,144,126,0.7);
	--rgba-primary-8: rgba(169,144,126,0.8);
	--rgba-primary-9: rgba(169,144,126,0.9);
	
	// Other
	--theme-text-color: #fff;
	--secondary: #ABC4AA;
	--title: #222222;
	--bg-light: #fff7f2;
	
}

[data-color="color_7"] {
	--primary: #4a90e2; /* Light Blue */
	--primary-hover: #357ABD; /* Darker Blue for hover */
	--primary-dark: #2c6398; /* Even darker blue */
	--rgba-primary-1: rgba(74, 144, 226, 0.1);
	--rgba-primary-2: rgba(74, 144, 226, 0.2);
	--rgba-primary-3: rgba(74, 144, 226, 0.3);
	--rgba-primary-4: rgba(74, 144, 226, 0.4);
	--rgba-primary-5: rgba(74, 144, 226, 0.5);
	--rgba-primary-05: rgba(74, 144, 226, 0.05);
	--rgba-primary-6: rgba(74, 144, 226, 0.6);
	--rgba-primary-7: rgba(74, 144, 226, 0.7);
	--rgba-primary-8: rgba(74, 144, 226, 0.8);
	--rgba-primary-9: rgba(74, 144, 226, 0.9);
	--theme-text-color: #fff;
	--secondary: #b0d4f1; /* Lighter Blue */
	--title: #222222;
	--bg-light: #e6f2fb; /* Light background blue */
  }
